.app {
  width: 100%;
  height: 100%;
}

.text {
  display: inline-block;
  font-weight: 600;
  color: red;
  font-size: 16px;
}